<template>
    <section class="obras-servicios-orden container-fluid overflow-auto custom-scroll">
        <div class="row">
            <div  class="col-12 mb-md-3 col-lg-9" >
                <div v-for="data in purchase_orders" @click="verOrdenCompra(data.id)" :key="data.id" class="cr-pointer">
                    <card-orden-compra 
                    :orderNumber="data.id"
                    :state="data.estado"
                    :date="data.created_at | helper-fecha('DD MMM. YYYY')"
                    :supplier="data.proveedor"
                    :value="data.valor"
                    :destiny="data.destino"
                    :paymentMethod="data.forma_pago"
                    :service="data.servicio"
                    :project="false"
                    :client="false"
                    :cotization="false"
                    @edit="openModalFormaPago(data.id)"
                    />
                </div>
            </div>
        </div>
        <modal-forma-pago ref="modalFormaPago" @save="savePaymentFees"/>
    </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import modalFormaPago from './partials/modalFormaPago'
export default {
    components: { modalFormaPago },
    data(){
        return {
            id_orden_compra: null,
        }
    },
    computed: {
        ...mapGetters({
            total_value: 'obras/servicios/forma_pago/total_value',
            purchase_orders: 'obras/servicios/orden_compra/purchase_orders',
            payment_methods: 'obras/servicios/forma_pago/payment_methods',
        }),
        id_proyecto(){
            return this.$route.params.id_proyecto
        },
        id_control_piso(){
            return this.$route.params.id_control_piso
        },
    },
    created(){
        this.Action_get_purchase_orders({
            id_proyecto: this.id_proyecto,
            id_control_piso: this.id_control_piso,
        })
        this.getSelects(['Action_get_select_monedas'])
    },
    methods:{
        ...mapActions({
            Action_get_purchase_orders: 'obras/servicios/orden_compra/Action_get_purchase_orders',
            Action_get_payment_methods: 'obras/servicios/orden_compra/Action_get_payment_methods',
            Action_save_payment_fees: 'obras/servicios/orden_compra/Action_save_payment_fees',
            Action_update_purchase_order_price: 'obras/servicios/orden_compra/Action_update_purchase_order_price',
            getSelects: 'selects/selects/sync'
        }),
        verOrdenCompra(id){
            this.$router.push({
                name: 'obras.vigentes.orden.compra.ver',
                params: { 
                    id_proyecto: this.id_proyecto, 
                    id_control_piso: this.id_control_piso, 
                    id_orden_compra: id
                }
            })
        },
        async openModalFormaPago(id_orden_compra){
            this.id_orden_compra = id_orden_compra
            await this.Action_get_payment_methods({
                id_proyecto: this.id_proyecto,
                id_control_piso: this.id_control_piso,
                id_orden_compra: this.id_orden_compra,
            })
            this.$refs.modalFormaPago.toggle()
        },
        async savePaymentFees(){
            await this.Action_save_payment_fees({
                id_proyecto: this.id_proyecto,
                id_control_piso: this.id_control_piso,
                id_orden_compra: this.id_orden_compra,
                payload: this.payment_methods,
            })
            this.Action_update_purchase_order_price({
                id_orden_compra: this.id_orden_compra,
                total: this.total_value
            })
            this.$refs.modalFormaPago.toggle()
            this.$refs.modalFormaPago.reset()
        }
    }
}
</script>

<style lang="scss" scoped>
.obras-servicios-orden{
    height: calc(100vh - 395px);
}
</style>