<template>
    <Modal ref="modalFormaPago" titulo="Forma de pago" tamano="modal-lg" adicional="Guardar" @adicional="handleSave">
        <FormaPagoTable ref="formaPagoTable" v-model="valid"/>
    </Modal>
</template>

<script>
import { mapActions } from 'vuex'
import FormaPagoTable from '../components/formaPagoTable'
export default {
    components: { FormaPagoTable },
    data(){
        return {
            valid: false
        }
    },
    methods: {
        ...mapActions({
            Action_validate_inputs: 'obras/servicios/forma_pago/Action_validate_inputs',
        }),
        toggle(){
            this.Action_validate_inputs()
            this.$refs.modalFormaPago.toggle()
        },
        handleSave(){
            this.Action_validate_inputs()
            if(this.valid) this.$emit('save')
        }, 
        reset(){
            this.$refs.formaPagoTable.reset()
        }       
    }
}
</script>