<template>
    <section>
        <ValidationObserver ref="validator">
            <div class="row justify-content-center br-2 f-600 py-2 border-bottom">
                <div class="col-2 text-center">
                    Cuota {{ index + 1 }}
                </div>
                <div class="col-3 text-center">
                    <ValidationProvider name="fecha de pago" rules="required" v-slot="{ errors }">
                        <el-date-picker
                        v-model="model.fecha_pago"
                        type="date"
                        placeholder="Seleccione un dia"
                        format="dd - MM - yyyy"
                        size="small"
                        class="w-100"
                        @change="saveDate"
                        />
                        <vee-error :text="errors[0]"/>
                    </ValidationProvider>
                </div>
                <div class="col-2">
                    <ValidationProvider name="porcentaje" rules="required|min_value:0|max_value:100" v-slot="{ errors }">
                        <el-input-number 
                        v-model="model.porcentaje" 
                        class="input-number w-100" 
                        size="small"
                        :min="0"
                        :max="100"
                        :disabled="tipo == 2"
                        @change="$emit('input', !!errors[0])"
                        :precision="2"
                        @blur="calculateValue"
                        />
                        <vee-error :text="errors[0]"/>
                    </ValidationProvider>
                </div>
                <div class="col-2">
                    <ValidationProvider name="moneda" rules="required" v-slot="{ errors }">
                        <el-select v-model="model.id_moneda" placeholder="Seleccionar" size="small" class="w-100" @change="saveMoney">
                            <el-option v-for="item in select_monedas" :key="item.id" :label="item.nombre" :value="item.id" />
                        </el-select>
                        <vee-error :text="errors[0]"/>
                    </ValidationProvider>
                </div>
                <div class="col-2 text-center">
                    <ValidationProvider name="porcentaje" rules="required" v-slot="{ errors }">
                        <CustomCurrencyInput
                        v-model="model.valor" 
                        :options="{ currency: 'USD', precision: 2 }"
                        :disabled="tipo == 1"
                        @change="$emit('input', !!errors[0])"
                        @blur="calculatePercentage"
                        />
                        <vee-error :text="errors[0]"/>
                    </ValidationProvider>
                </div>
                <div class="col-1 text-center">
                    <i class="icon-close-circle cr-pointer text-ddd f-20" @click="handleRemove"/>
                </div>            
            </div>
        </ValidationObserver>
    </section>
</template>

<script>
import moment from 'moment'
import { mapActions, mapGetters } from 'vuex'
const model = {
    id: null,
    fecha_pago: '',
    porcentaje: 0,
    id_moneda: null,
    valor: 0,
    tipo: 1,
}
export default {
    props: {
        id: {
            type: Number  
        },
        index: {
            type: Number
        },
        date: { 
            type: String,
            default: ''
        },
        percentage: {
            type: [Number, String],
            default: 0
        },
        val: {
            type: [Number, String],
            default: 0
        },
        type: {
            type: Number,
            default: 1
        },
        money: {
            type: Number,
            default: null
        }
    },
    data(){
        return{
            model: model
        }
    },
    computed:{
        ...mapGetters({
            validate_inputs: 'obras/servicios/forma_pago/validate_inputs',
            total_percentage: 'obras/servicios/forma_pago/total_percentage',
            total_value: 'obras/servicios/forma_pago/total_value',
            tipo: 'obras/servicios/forma_pago/type',
            select_monedas: 'selects/selects/select_monedas'
        })
    },
    watch: {
        validate_inputs(){
            this.validate()
        },
        date(val){
            this.model.fecha_pago = val
        },
        percentage(val){
            this.model.porcentaje = val
        },
        val(val){
            this.model.valor = val
        },

    },
    created(){
        this.model = {
            id: this.id,
            porcentaje: this.percentage,
            valor: this.val,
            id_moneda: this.money,
            fecha_pago: this.date,
            tipo: this.type,
        }
    },
    mounted(){
        this.validate()
    },
    methods:{
        ...mapActions({
            Action_calculate_values_by_percentage: 'obras/servicios/forma_pago/Action_calculate_values_by_percentage',
            Action_calculate_values_by_value: 'obras/servicios/forma_pago/Action_calculate_values_by_value',
            Action_save_date: 'obras/servicios/forma_pago/Action_save_date',
            Action_save_money: 'obras/servicios/forma_pago/Action_save_money',
        }),
        async validate(){
            const valid = await this.$refs.validator.validate()
            this.$emit('input', valid)
        },
        saveDate(){
            this.Action_save_date({
                index: this.index,
                date: moment(this.model.fecha_pago).format('YYYY-MM-DD')
            })
            this.validate()
        },
        saveMoney(){
            this.Action_save_money({
                index: this.index,
                id_moneda: this.model.id_moneda
            })
            this.validate()
        },
        calculatePercentage(){
            this.Action_calculate_values_by_value({
                index: this.index, 
                value: this.model.valor
            })
            this.validate()
        },
        calculateValue(){
            this.Action_calculate_values_by_percentage({
                index: this.index, 
                percentage: this.model.porcentaje
            })
            this.validate()
        },
        handleRemove(){
            this.$emit('remove')
            if(!this.id){
                this.model = {...model}
                this.$refs.validator.reset()
            }
        }
    },
    beforeDestroy(){
        this.$emit('input', undefined)
    }
}
</script>

<style lang="scss">
.input-number{
    span{
        display: none;
    }
}
</style>