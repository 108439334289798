<template>
    <div class="row mx-5">
        <div class="col-12">
            <div class="row py-3">
                <div class="col-5">
                    <p>
                        <span class="f-600 f-12">Forma de pago: </span>
                        <span class="f-12 ml-3">Cuotas</span>
                    </p>
                </div>
                <div class="col-7">
                    <div class="d-flex align-items-center gap-2">
                        <p class="f-600 f-12">Fijar cuota por: </p>
                        <el-radio v-model="tipo" :label="1">Porcentaje</el-radio>
                        <el-radio v-model="tipo" :label="2">Valor</el-radio>
                    </div>
                </div>
            </div>
            <div class="row justify-content-center bg-general text-white br-2 f-600 py-2">
                <div class="col-2 text-center">
                    Cuotas
                </div>
                <div class="col-3 text-center">
                    Fecha de pago
                </div>
                <div class="col-2 text-center">
                    Porcentaje
                </div>
                <div class="col-2 text-center">
                    Moneda
                </div>
                <div class="col-2 text-center">
                    Valor
                </div>
                <div class="col-1 text-center p-0">
                    <div class="row justify-content-center align-items-center">
                        <div class="col-6 px-1">
                            <el-tooltip content="Número de cuotas" placement="top" effect="light">
                                <el-input-number 
                                v-model="quantity"
                                size="mini" 
                                :min="MIN_COUTAS" 
                                :max="MAX_COUTAS" 
                                class="w-100" 
                                controls-position="right"
                                />
                            </el-tooltip>
                        </div>
                        <div class="col-auto px-1">
                            <i class="icon-plus-box cr-pointer" @click="handleAddFees"/>
                        </div>
                    </div>
                </div>
            </div>
            <template v-for="(item, index) in payment_methods">
                <FormaPagoRow 
                    v-model="valid[index]"
                    :index="index"
                    :date="item.fecha_pago"
                    :percentage="item.porcentaje"
                    :type="item.tipo"
                    :val="item.valor"
                    :money="item.id_moneda"
                    @remove="Action_remove_payment_method(item.id)"
                />
            </template>
            <div class="row pt-2">
                <div class="col-5 text-right">
                    <div class="bg-f5 border float-right f-600 f-14 br-5 py-1 px-2">TOTAL</div>
                </div>
                <div class="col-2">
                    <div class="bg-f5 border text-center f-600 f-14 br-5 py-1 px-2" :class="{'border border-danger': !valid_percentage}">{{ total_percentage }}%</div>
                </div>
                <div class="col-3">
                    <CustomCurrencyInput
                    v-model="total" 
                    :options="{ currency: 'USD', precision: 2 }"
                    :disabled="tipo == 2"
                    @blur="Action_calculate_values_by_total(total)"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import FormaPagoRow from '../components/formaPagoRow'
export default {
    components: { FormaPagoRow },
    data(){
        return{
            quantity: 0,
            MIN_COUTAS: 0,
            MAX_COUTAS: 20,
            total: 0,
            valid: []
        }
    },
    computed: {
        ...mapGetters({
            total_percentage: 'obras/servicios/forma_pago/total_percentage',
            total_value: 'obras/servicios/forma_pago/total_value',
        }),
        tipo: {
            get(){
                return this.$store.getters['obras/servicios/forma_pago/type']
            },
            set(val){
                this.$store.commit('obras/servicios/forma_pago/set_type', val)
            }
        },
        payment_methods: {
            get(){
                return this.$store.getters['obras/servicios/forma_pago/payment_methods']
            },
            set(val){
                this.$store.commit('obras/servicios/forma_pago/set_payment_methods', val)
            }
        },
        valid_percentage(){
            return this.total_percentage == 100
        },
        valid_inputs(){
            return this.valid.length && this.valid.filter(el => typeof el != 'undefined').every(el => Boolean(el))
        },
        valid_form(){
            return this.valid_inputs && this.valid_percentage
        },
        tipo_cuota(){
            return this.$route.query?.tipo_cuota ?? 1
        }
    },
    watch:{
        total_value(val){
            this.total = val
        },
        valid_form(val){
            this.$emit('input', val)
        },
        tipo(val){
            this.$router.push({ query: { tipo_cuota: val }})
        }
    },
    mounted(){
        if(!this.tipo_cuota) this.$router.push({ query: { tipo_cuota: this.tipo }})
        const { tipo_cuota } = this.$route.query
        if(tipo_cuota) this.tipo = parseInt(tipo_cuota)
    },
    methods:{
        ...mapActions({
            Action_add_new_payment_method: 'obras/servicios/forma_pago/Action_add_new_payment_method',
            Action_remove_payment_method: 'obras/servicios/forma_pago/Action_remove_payment_method',
            Action_calculate_values_by_total: 'obras/servicios/forma_pago/Action_calculate_values_by_total',
        }),
        reset(){
            this.valid = []
            this.feeCount = 0
            this.fees = 0
        },
        async handleAddFees(){
            if((this.quantity) <= this.MAX_COUTAS){
                this.Action_add_new_payment_method(parseInt(this.quantity))
            }
        }
    }
}
</script>

<style lang="scss">
.el-input-number{
    input{
        padding: 0 10px !important;
    }
    span{
        width: 15px !important;
    }
}
</style>