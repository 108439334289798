var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('ValidationObserver',{ref:"validator"},[_c('div',{staticClass:"row justify-content-center br-2 f-600 py-2 border-bottom"},[_c('div',{staticClass:"col-2 text-center"},[_vm._v(" Cuota "+_vm._s(_vm.index + 1)+" ")]),_c('div',{staticClass:"col-3 text-center"},[_c('ValidationProvider',{attrs:{"name":"fecha de pago","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('el-date-picker',{staticClass:"w-100",attrs:{"type":"date","placeholder":"Seleccione un dia","format":"dd - MM - yyyy","size":"small"},on:{"change":_vm.saveDate},model:{value:(_vm.model.fecha_pago),callback:function ($$v) {_vm.$set(_vm.model, "fecha_pago", $$v)},expression:"model.fecha_pago"}}),_c('vee-error',{attrs:{"text":errors[0]}})]}}])})],1),_c('div',{staticClass:"col-2"},[_c('ValidationProvider',{attrs:{"name":"porcentaje","rules":"required|min_value:0|max_value:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('el-input-number',{staticClass:"input-number w-100",attrs:{"size":"small","min":0,"max":100,"disabled":_vm.tipo == 2,"precision":2},on:{"change":function($event){return _vm.$emit('input', !!errors[0])},"blur":_vm.calculateValue},model:{value:(_vm.model.porcentaje),callback:function ($$v) {_vm.$set(_vm.model, "porcentaje", $$v)},expression:"model.porcentaje"}}),_c('vee-error',{attrs:{"text":errors[0]}})]}}])})],1),_c('div',{staticClass:"col-2"},[_c('ValidationProvider',{attrs:{"name":"moneda","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('el-select',{staticClass:"w-100",attrs:{"placeholder":"Seleccionar","size":"small"},on:{"change":_vm.saveMoney},model:{value:(_vm.model.id_moneda),callback:function ($$v) {_vm.$set(_vm.model, "id_moneda", $$v)},expression:"model.id_moneda"}},_vm._l((_vm.select_monedas),function(item){return _c('el-option',{key:item.id,attrs:{"label":item.nombre,"value":item.id}})}),1),_c('vee-error',{attrs:{"text":errors[0]}})]}}])})],1),_c('div',{staticClass:"col-2 text-center"},[_c('ValidationProvider',{attrs:{"name":"porcentaje","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('CustomCurrencyInput',{attrs:{"options":{ currency: 'USD', precision: 2 },"disabled":_vm.tipo == 1},on:{"change":function($event){return _vm.$emit('input', !!errors[0])},"blur":_vm.calculatePercentage},model:{value:(_vm.model.valor),callback:function ($$v) {_vm.$set(_vm.model, "valor", $$v)},expression:"model.valor"}}),_c('vee-error',{attrs:{"text":errors[0]}})]}}])})],1),_c('div',{staticClass:"col-1 text-center"},[_c('i',{staticClass:"icon-close-circle cr-pointer text-ddd f-20",on:{"click":_vm.handleRemove}})])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }